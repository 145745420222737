import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loader(props) {
  return (<Grid container="container" direction="column" justify="center" alignItems="center" style={{
      height: typeof window !== `undefined`
        ? window.innerHeight
        : '90vh',
      width: '100vw'
    }}>
    <h1 style={{
        fontSize: '25px',
        fontWeight: '200',
        color: 'grey',
        padding: '10px',
        textAlign: 'center'
      }}>{props.message}</h1>
    <CircularProgress color='primary'/>
  </Grid>)
}

// <SEO title='Loading'/>
